import { VscBook } from "react-icons/vsc";
import { GoTrash } from "react-icons/go";
import { LuFileEdit } from "react-icons/lu";
import { FcCalendar } from "react-icons/fc";
import Editor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { deleteData, readDocument, updateData, uploadData } from "../endpoints";
import { Feedback } from "../components/modals/feedback";
import { _courses } from "../assets/data/courses";

export default function Assignments({ profile, settings }) {
  const [subject, setSubject] = useState("");
  const [question, setQuestion] = useState(null);
  const [edit, setEdit] = useState({
    editMode: false,
    id: "",
  });
  const [call, shouldCall] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    readDocument({
      path: "/assignment",
      id: `${profile?.class_assigned}/${profile.division}?term=${settings.currentTerm}&academic_year=${settings.academicYear}&call=${call}`,
      getData: setQuestions,
    });
  }, [profile, settings, call]);

  const sendAssignment = () => {
    const data = {
      subject,
      question,
      class: profile?.class_assigned,
      division: profile?.division,
      term: settings?.currentTerm,
      academic_year: settings?.academicYear,
      posted_by: profile?.first_name + " " + profile?.last_name,
    };

    if(edit.editMode){
      updateData({
        path: "/assignment",
        id:edit?.id,
        data,
      })
        .then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Assignment Successfully Posted",
          });
          setEdit({
            editMode:false
          })
          setTimeout(() => setQuestion(null), 500);
          shouldCall(new Date().getSeconds());
        })
        .catch((err) => {
          setResponse({
            open: true,
            type: "error",
            message: err.message,
          });
        });
    }else{
      uploadData({
        path: "/assignment/",
        data,
      })
        .then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Assignment Successfully Posted",
          });
          setEdit({
            editMode:false
          })
          setTimeout(() => setQuestion(null), 500);
          shouldCall(new Date().getSeconds());
        })
        .catch((err) => {
          setResponse({
            open: true,
            type: "error",
            message: err.message,
          });
        });
    }
  
  };

  const editQuestion = (q) => {
    setEdit({
      editMode: true,
      id: q.id,
    });
    setSubject(q.subject);
    setQuestion(q.question);
  };

  const deleteQuestion = (id) => {
    deleteData({
      path: "/assignment",
      id,
    })
      .then(() => {
        setResponse({
          open: true,
          message: "Delete Successful",
          type: "success",
        });

        shouldCall(new Date().getSeconds());
      })
      .catch((err) =>
        setResponse({
          open: true,
          message: err.message,
          type: "error",
        })
      );
  };

  return (
    <div className="flex flex-col md:flex-row flex-1 p-3 max-h-[90vh] overflow-hidde">
      <div className="w-full md:w-2/3  h-2/3 md:h-full bg-gray-100">
        <select
          onChange={(e) => setSubject(e.target.value)}
          className="w-56 p-2 shadow-md my-3 border-none"
        >
          <option value="">Select Subject</option>
          {_courses.map((crs, idx) => (
            <option value={crs.value} key={idx}>
              {crs.text}
            </option>
          ))}
        </select>
        <Editor
          value={question}
          onChange={setQuestion}
          placeholder="Enter Questions Here"
          theme="snow"
          className=" w-[90%]  h-72 md:h-80  mx-auto"
        />
        <button
          disabled={!subject || question === null}
          onClick={sendAssignment}
          className="p-2 bg-yellow-500 text-sm md:ml-10 md:mt-14 font-medium rounded-sm shrink-0"
        >
          {edit.editMode ? "Update Question" : "Submit"}
        </button>
      </div>
      <div className="flex-1 flex flex-col bg-gray-200/30 p-5 overflow-y-auto h-[94vh]">
        <h1 className="p-2 font-bold text-lg">Posted Assignments</h1>

        {(questions?.length && (
          <div className="flex flex-col gap-3">
            {questions?.map((q, idx) => (
              <div
                className="flex flex-col w-full p-3 bg-white shadow-md rounded-md"
                key={idx}
              >
                <div className="flex items-center justify-between">
                  <span className="flex items-center gap-2">
                    {" "}
                    <VscBook className="text-gray-600" /> <p>{q.subject}</p>
                  </span>

                  <div className="flex gap-4 justify-end">
                    <button
                      title="Delete"
                      onClick={() => 
                        deleteQuestion(q.id)
                      }
                    >
                      <GoTrash className="text-red-500 text-lg" />
                    </button>
                    <button title="Edit" onClick={() => editQuestion(q)}>
                      <LuFileEdit className="text-blue-500 text-lg" />
                    </button>
                  </div>
                </div>

                <div
                  className="text-sm leading-relaxed my-2"
                  dangerouslySetInnerHTML={{ __html: q.question }}
                />

                <span className="flex items-center gap-2">
                  {" "}
                  <FcCalendar />{" "}
                  <p className="text-xs">
                    {new Date(q.createdAt).toLocaleString("ru")}
                  </p>
                </span>
              </div>
            ))}
          </div>
        )) || (
          <div className="w-full flex flex-col items-center justify-center h-56">
            <div className="w-24 h-24 animate-pulse">
              <img src="https://cdn4.iconfinder.com/data/icons/education-862/135/Homework-256.png" alt="No Homework" />
            </div>
            <p className="text-xl text-red-500">No homeworks yet.</p>
          </div>
        )}
      </div>
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
    </div>
  );
}
