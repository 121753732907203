export const _courses = [
  {
    text: "Mathematics",
    value: "Mathematics",
  },
  {
    text: "English",
    value: "English Language",
  },
  {
    text: "Science",
    value: "Integrated Science",
  },
  {
    text: "Computing",
    value: "Computing",
  },
  {
    text: "R M E",
    value: "RME",
  },
  {
    text: "History",
    value: "History",
  },

  {
    text: "OWOP",
    value: "OWOP",
  },
  {
    text: "Fantse",
    value: "Fantse",
  },
  {
    text: "French",
    value: "French",
  },
  {
    text: "C. Arts",
    value: "Creative Arts",
  },
  {
    text: "P E",
    value: "Physical and Health Education",
  },
];
export const subjects = [
  {
    value: "English Language",
    text: "English Language",
    isCore:true,
  },
  {
    value: "Mathematics",
    text: "Mathematics",
    isCore:true,
  },
  {
    value:"Integrated Science",
    text:"Integrated Science",
    isCore:true,
  },
  {
    value: "Social Studies",
    text: "Social Studies",
    isCore:true,
  },
  {
    value: "R M E",
    text: "R M E",
    isCore:false,
  },
  {
    value: "French",
    text: "French",
    isCore:false,
  },
  {
    value: "Ghanaian Language",
    text: "Ghanaian Language",
    isCore:false,
  },
  {
    value: "Computing",
    text: "Computing",
    isCore:false,
  },
  {
    value: "Career Technology",
    text: "Career Technology",
    isCore:false,
  },
  {
    value: "Creative Arts and Design",
    text: "Creative Arts and Design",
    isCore:false,

  },
];
export const table_courses = [
  {
    text: "UCMAS",
    value: "UCMAS",
  },
  {
    text: "Coding",
    value: "Coding",
  },
  {
    text: "Extra Curricular",
    value: "Extra Curricular",
  },
  {
    text: "Singing",
    value: "Singing",
  },
  {
    text: "Worship",
    value: "Worship",
  },
  {
    text: "Mathematics",
    value: "Mathematics",
  },
  {
    text: "English",
    value: "English Language",
  },

  {
    text: "Science",
    value: "Integrated Science",
  },

  {
    text: "Social",
    value: "Social Studies",
  },
  {
    text: "I C T",
    value: "Info & Comm. Tech.",
  },
  {
    text: "R M E",
    value: "Religious & Moral Edu.",
  },
  {
    text: "B D T",
    value: "Basic Design & Tech.",
  },
  {
    text: "History",
    value: "History",
  },
  /*
    {
        text: 'O W O P',
        value: 'Our World Our People'
    },*/
  {
    text: "Fantse",
    value: "Fantse",
  },
  {
    text: "French",
    value: "French",
  },
  {
    text: "C Arts",
    value: "Creative Arts",
  },
  {
    text: "C. Tech",
    value: "Career Technology",
  },
  {
    text: "P & Health",
    value: "Physical and Health Education",
  },
];


export const terms = ["First Term", "Second Term", "Third Term"]
export const mocks = ["Mock Exams", "Mock Exams 2", "Mock Exams 3", "Mock Exams 4", "Mock Exams 5", "Mock Exams 6"]

